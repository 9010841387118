import {
	Button,
	Group,
	Paper,
	Select,
	Stack,
	Text,
	Tooltip,
} from "@mantine/core";
import { Icon } from "@iconify/react";
import { useController, useFormContext } from "react-hook-form";
import { useCallback, useMemo, useState } from "react";
import { css } from "@emotion/css";
import styled from "@emotion/styled";
import GameItem from "./GameItem";
import { MiniGame } from "api/interfaces/locationEvents";
// import { useMerchantNavigation } from "hooks/useMerchantNavigation";
// import useMerchantGames from "features/MerchantGames/hooks/useMerchantGames";
import { useParams } from "react-router";
import useMerchantGames from "features/MerchantGames/hooks/useMerchantGames";
import { GameSettings } from "api/interfaces/locationEvents";

type GameData = {
	Game: {
		id: number,
		name: string,
	}
};

type SelectData = {
	value: string,
	label: string
};

type GamesListData = {
	gameId: number,
	name: string,
	frequencyOccurrence: number,
	maxAttempts: number,
};

// Component
export default function ContentSelect() {
	const { control } = useFormContext();
	const { field } = useController({ name: "gamesList", control });
	const { field: gameSettings } = useController({ name: "gameSettings", control });

	const { id, merchantId } = useParams();
	const { data: merchantGames } = useMerchantGames(merchantId!);

	const [selectId, setSelectId] = useState<MiniGame["id"] | undefined | null>(undefined);

	const contentList: any = useMemo(() => {
		if (!merchantGames) return [];

		const formattedData = merchantGames.map((item: GameData) => {
			return {
				id: item.Game.id,
				name: item.Game.name,
			}
		});

		return (gameSettings.value || [])
			.sort((a:any,b:any)=>a.gameId-b.gameId)
			.map((settings: GameSettings) => {
				const content = formattedData.find(
					(item: MiniGame) => item.id === settings.gameId
				);
				if (!content) return undefined;
				return {
					gameId: content.id,
					name: content.name,
					frequencyOccurrence: settings.frequencyOccurrence,
					maxAttempts: settings.maxAttempts,
				};
			})
			.filter((item: MiniGame | undefined) => !!item);
	}, [merchantGames, field.value, gameSettings.value]);


	const contentsData = useMemo(() => {
		if (!merchantGames) return [];
		const formattedData = merchantGames.map((item: GameData) => {
			return {
				value: String(item.Game.id),
				label: item.Game.name,
			}
		});
		const ids = gameSettings.value.map((item: GameSettings) => item.gameId);
		return formattedData.filter((item: SelectData) => !ids.includes(Number(item.value)));
	}, [merchantGames, field]);


	const onRemove = useCallback((inputId: MiniGame["id"]) => {
		if (!field.value.includes(inputId)) return;

		const values = field.value.filter((id: MiniGame["id"]) => inputId !== id);
		const settingsValues = gameSettings.value.filter((item: GameSettings) => item.gameId !== inputId);
		field.onChange(values);
		gameSettings.onChange(settingsValues);
	}, [field]);

	const onAdd = useCallback(() => {
		if (!selectId) return;
		field.onChange([Number(selectId), ...field.value]);
		gameSettings.onChange([
			{
				gameId: Number(selectId),
				frequencyOccurrence: 0.05,
				maxAttempts: 1,
			},
			...gameSettings.value
		]);
		setSelectId(undefined);
	}, [field, selectId]);

	return (
		<Paper withBorder radius={"md"} style={{ overflow: "hidden" }}>
			<Stack spacing={0}>
				{/* Header */}
				<Group grow p={"lg"} style={{ borderBottom: "1px solid #e9ecef" }}>
					<Group position="left" pr={30}>
						<Text size="md" weight="bold">
							Choose game
						</Text>

						<Tooltip
							multiline
							width={220}
							withArrow
							label="You can choose any game from the list.."
						>
							<Icon icon="material-symbols:info-outline" />
						</Tooltip>
					</Group>
					<Group position="right">
						<SelectField
							placeholder="Pick one"
							searchable
							nothingFound="No options"
							data={contentsData}
							classNames={{ input: "input-field" }}
							onChange={(id) => setSelectId(id)}
							value={String(selectId)}
							sx={{ width: "100%", maxWidth: 300 }}
						/>
						<Button
							leftIcon={<Icon icon={"fluent:add-12-filled"} height={18} />}
							radius={"sm"}
							color={"dark"}
							variant={"filled"}
							onClick={onAdd}
							disabled={!selectId}
							sx={{ maxWidth: 300 }}
						>
							Add Game
						</Button>
					</Group>
				</Group>

				{/* Content */}
				<Stack spacing={0}>
					{!contentList.length && (
						<Group grow p={"lg"}>
							<Text size="sm" color={"dimmed"}>
								Not found...
							</Text>
						</Group>
					)}
					{contentList.map((content: GamesListData) => (
						<GameItem
							key={Number(content.gameId)}
							content={content}
							merchantId={merchantId!}
							eventId={id}
							onRemove={() => onRemove(content.gameId)}
						/>
					))}
				</Stack>
			</Stack>
		</Paper>
	);
}

// CSS Helper
const InputCSS = css`
  background: #fcfdfe;
  border: 1px solid #f0f1f7;
  color: #9fa2b4;
  outline: none;
  transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out,
    color 0.2s ease-in-out;

  &:hover,
  &:focus {
    color: #252733;
    border-color: #0095e5;
  }

  &::placeholder {
    color: rgb(75, 80, 109, 0.5);
  }
`;

const SelectField = styled(Select)`
  flex: 1;

  & .input-field {
    ${InputCSS};
  }
`;
