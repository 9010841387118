import {
    Button,
    Card,
    Group,
    Stack,
    Text,
    TextInput,
    Select,
    NumberInput,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { Controller, useFormContext } from "react-hook-form";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { useRewards } from "./hooks/useRewards";
import { useQuestActions } from "./hooks/useQuestActions";


export const QuestForm = () => {
    const form = useFormContext();
    const { id } = useParams();
    const { rewards } = useRewards();
    const currentDate = new Date();
    const minEndDate = dayjs().add(1, "day").toDate();
    const actionData = useQuestActions();

    return (
        <Card radius={8} shadow="0px 1px 4px rgba(0, 0, 0, 0.05)">
            <Card.Section inheritPadding py={16} sx={{ borderBottom: "1px solid #f8f9fa" }}>
                <Group position="apart">
                    <Text size={16} weight={600} lh={1.5}>
                        Overview
                    </Text>
                    <Group>
                        <Button
                            loading={form.formState.isSubmitting}
                            disabled={!form.formState.isValid} type="submit"
                            variant="filled"
                            h={28}
                            px={12}
                            py={4}
                            color="dark.8"
                            radius={4}
                        >
                            <Text size={"14px"} weight={500} tt={'uppercase'} lh={1.5}>{id ? "UPDATE" : "CREATE"}</Text>
                        </Button>
                    </Group>
                </Group>
            </Card.Section>
            <Card.Section inheritPadding p={24}>
                <Stack spacing={16}>
                    <Controller
                        name="title"
                        control={form.control}
                        render={({ field, fieldState }) => (
                            <TextField
                                onChange={field.onChange}
                                label="Name"
                                value={field.value}
                                placeholder="Enter quest title"
                                required
                                styles={{
                                    label: {
                                        marginBottom: 12,
                                    },
                                    input: {
                                        background: '#FCFDFE',
                                        border: '1px solid #F1F3F5',
                                        borderRadius: '4px',
                                        "::placeholder": {
                                            color: '#9FA2B4'
                                        }
                                    }
                                }}
                                error={fieldState.error && fieldState.error?.message}
                            />
                        )}
                    />

                    <Controller
                        name="description"
                        control={form.control}
                        render={({ field, fieldState }) => (
                            <TextField
                                label="Descpiption"
                                placeholder="Enter quest description"
                                value={field.value}
                                required
                                onChange={field.onChange}
                                error={fieldState.error && fieldState.error?.message}
                                styles={{
                                    label: {
                                        marginBottom: 12,
                                    },
                                    input: {
                                        background: '#FCFDFE',
                                        border: '1px solid #F1F3F5',
                                        borderRadius: '4px',
                                        "::placeholder": {
                                            color: '#9FA2B4'
                                        }
                                    }
                                }}
                            />
                        )}
                    />

                    <Group grow spacing={"lg"}>
                        <Controller
                            name="actionId"
                            control={form.control}
                            render={({ field, fieldState }) => (
                                <SelectField
                                    value={field.value}
                                    label="Quest action type"
                                    placeholder={(actionData.length > 0 && id) ? actionData.filter((data) => data.value == field.value)[0]?.label : "Select action"}
                                    variant="filled"
                                    data={actionData as any}
                                    onChange={field.onChange}
                                    withinPortal
                                    withAsterisk
                                    required
                                    error={fieldState.error && fieldState.error?.message}
                                    styles={{
                                        label: {
                                            marginBottom: 12,
                                        },
                                        input: {
                                            background: '#FCFDFE',
                                            border: '1px solid #F1F3F5',
                                            borderRadius: '4px',
                                            "::placeholder": {
                                                color: '#9FA2B4'
                                            }
                                        }
                                    }}
                                />
                            )}
                        />
                        <Controller
                            name="actionCount"
                            control={form.control}
                            render={({ field, fieldState }) => (
                                <NumberField
                                    label="Action count"
                                    placeholder="Enter action count"
                                    required
                                    onChange={field.onChange}
                                    error={fieldState.error && fieldState.error?.message}
                                    value={field.value}
                                    styles={{
                                        label: {
                                            marginBottom: 12,
                                        },
                                        input: {
                                            background: '#FCFDFE',
                                            border: '1px solid #F1F3F5',
                                            borderRadius: '4px',
                                            "::placeholder": {
                                                color: '#9FA2B4'
                                            }
                                        }
                                    }}
                                />
                            )}
                        />
                    </Group>



                    <Group grow spacing={"lg"} align={"flex-start"}>
                        {/* Start Date */}
                        <Stack>
                            <Controller
                                name="startDate"
                                control={form.control}
                                render={({ field, fieldState }) => (
                                    <DatePickField
                                        label="Start date"
                                        placeholder="Pick date"
                                        variant="filled"
                                        minDate={currentDate}
                                        required
                                        classNames={{ input: "input-field" }}
                                        error={fieldState.error?.message}
                                        value={field.value ? new Date(field.value) : undefined}
                                        onChange={field.onChange}
                                        styles={{
                                            label: {
                                                marginBottom: 12,
                                            },
                                        }}
                                    />
                                )}
                            />
                        </Stack>

                        {/* End Date */}
                        <Stack>
                            <Controller
                                name="endDate"
                                control={form.control}
                                render={({ field, fieldState }) => (
                                    <DatePickField
                                        label="End date"
                                        placeholder="Pick date"
                                        variant="filled"
                                        minDate={minEndDate}
                                        required
                                        classNames={{ input: "input-field" }}
                                        error={fieldState.error?.message}
                                        value={field.value ? new Date(field.value) : undefined}
                                        onChange={field.onChange}
                                        styles={{
                                            label: {
                                                marginBottom: 12,
                                            },
                                        }}
                                    />
                                )}
                            />
                        </Stack>
                    </Group>
                    <Group grow spacing={"lg"}>
                        <Controller
                            name="questContents"
                            control={form.control}
                            render={({ field, fieldState }) => (
                                <SelectField
                                    value={field.value}
                                    label="Quest reward"
                                    placeholder={(rewards.length > 0  && id) ? rewards.filter((reward) => reward.value == field.value)[0]?.label : "Select reward"}
                                    variant="filled"
                                    data={rewards as any}
                                    onChange={field.onChange}
                                    withinPortal
                                    withAsterisk
                                    required
                                    error={fieldState.error && fieldState.error?.message}
                                    styles={{
                                        label: {
                                            marginBottom: 12,
                                        },
                                        input: {
                                            background: '#FCFDFE',
                                            border: '1px solid #F1F3F5',
                                            borderRadius: '4px',
                                            "::placeholder": {
                                                color: '#9FA2B4'
                                            }
                                        }
                                    }}
                                />
                            )}
                        />
                    </Group>
                </Stack>
            </Card.Section>
        </Card>
    );
}

const InputCSS = css`
	display: flex;
	font-size: 14px;
	font-weight: 400;
	background: #fcfdfe;
	color: #9fa2b4;
	line-height: 1.5;
	min-height: 42px;
	outline: none;
	padding: 10px 16px;
	width: 100%;
	transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out, color 0.2s ease-in-out;

	&:hover,
	&:focus {
		color: #252733;
		border-color: #0095e5;
	}

	&::placeholder {
		color: rgb(75, 80, 109, 0.5);
	}
`;

const FieldCSS = css`
	flex: 1;

	& .input-field {
		${InputCSS};
	}
`;

const TextField = styled<any>(TextInput)`
	${FieldCSS};
	position: relative;

	.mantine-TextInput-error {
		position: absolute;
		left: 0;
		bottom: -24px;
	}

	& .input-field {
		border: 1px solid ${({ error }) => (error ? "#f03e3e" : "#f0f1f7")};
	}
`;

const NumberField = styled<any>(NumberInput)`
	${FieldCSS};
	position: relative;

	.mantine-TextInput-error {
		position: absolute;
		left: 0;
		bottom: -24px;
	}

	& .input-field {
		border: 1px solid ${({ error }) => (error ? "#f03e3e" : "#f0f1f7")};
	}
`;

const SelectField = styled(Select)`
	${FieldCSS};

	& .input-field {
		border: 1px solid ${({ error }) => (error ? "#f03e3e" : "#f0f1f7")};
	}
`;

const DatePickField = styled(DatePickerInput as any)`
	${FieldCSS};
`;
