import styled from "@emotion/styled";
import { ActionIcon, Group, Text, Stack, Title } from "@mantine/core";
import { TQuest } from "../types";
import { Icon } from "@iconify/react";
import useMutationData from "hooks/useMutationData";
import { useNavigate } from "react-router-dom";

// Props
type ComponentProps = {
    quest: TQuest;
};

// Component
export default function QuestItem({ quest }: ComponentProps) {
    const navigate = useNavigate();

    const removeRequest = useMutationData({
        url: () => `/admin/quests/${quest.id}`,
        method: "delete",
        queryKeys: {
            invalidate: [{ queryKey: ["QUEST_LIST"] }],
        },
    });

    return (
        <Root position="apart" px={16} py={8}>
            <Stack spacing={0}>
                <Title order={5}>{quest.title}</Title>
                <Text
                    size="sm"
                    color={'dimmed'}>
                    {quest.description}
                </Text>
            </Stack>
            <Group position="right" spacing={8}>
                <ActionIcon
                    disabled={removeRequest.isLoading}
                    variant="subtle"
                    color="gray.5"
                    onClick={() => navigate(`/platform/quests/${quest.id}`)}
                    sx={(theme) => ({
                        background: "#FCFDFE",
                        border: "1px solid #F1F3F5",
                        borderRadius: "6px",
                        color: theme.colors.dark[2],
                        "& svg[data-action-icon-loader]": {
                            stroke: theme.colors.dark[2],
                        },
                    })}
                >
                    <Icon icon={"fluent:edit-16-regular"} height={14} />
                </ActionIcon>
                <ActionIcon
                    variant={"subtle"}
                    onClick={() => removeRequest.mutate(quest.id)}
                    loading={removeRequest.isLoading}
                    sx={(theme) => ({
                        background: "#FCFDFE",
                        border: "1px solid #F1F3F5",
                        borderRadius: "6px",
                        color: theme.colors.red[5],
                        "& svg[data-action-icon-loader]": {
                            stroke: theme.colors.red[5],
                        },
                    })}
                >
                    <Icon icon={"fluent:delete-16-regular"} height={14} />
                </ActionIcon>
            </Group>
        </Root>
    );
}

const Root = styled(Group)`
  &:not(:last-child) {
    border-bottom: 1px solid #f8f9fa;
  }
`;
