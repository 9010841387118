import { useServerConstants } from "hooks/useServerConstants";

export const useQuestActions = () => {
    const constants = useServerConstants();

    const actionData =  constants.QuestAction.map((action) => {
        return {
            label: action.name,
            value: action.id,
        }
    });

    return actionData;
}
