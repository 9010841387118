import { Button, Card, Group, Stack, Text } from "@mantine/core";
import ContentLoader from "components/ContentLoader";
import { CreateForm } from "features/PlatformGames/components/CreateForm";
import { TQuest } from "features/Quests/types";
import Modal from "features/Modal";
import { ModalTrigger } from "features/Modal/constants";
import usePaginatedData from "hooks/usePaginatedData";
import { useNavigate } from "react-router-dom";
import QuestItem from "features/Quests/components/QuestItem";

export default function QuestsPage() {
    const navigate = useNavigate();

    const { items, isLoading } = usePaginatedData<TQuest>({
        path: "/admin/quests",
        defaultLimit: 1000,
        queryKey: ["QUEST_LIST"],
    });

    return (
        <Stack p={24} spacing={24}>
            <Card radius={8} shadow="0px 1px 4px rgba(0, 0, 0, 0.05)">
                <Card.Section inheritPadding py={16} sx={{ borderBottom: "1px solid #f8f9fa" }}>
                    <Group position="apart">
                        <Text size={16} weight={600} lh={1.5}>
                            Quests
                        </Text>
                        <Group>
                            <Button type="submit" variant="filled" h={28} px={12} py={4} color="dark.8" radius={4} onClick={() => navigate('/platform/quests/create')}>
                                <Text size={"14px"} weight={500} tt={"uppercase"} lh={1.5}>
                                    ADD
                                </Text>
                            </Button>
                        </Group>
                    </Group>
                </Card.Section>
                <Card.Section inheritPadding p={0} pb={2}>
                    <Stack spacing={0}>
                        {isLoading && <ContentLoader />}

                        {items.length > 0 &&
                            items.map((item) => <QuestItem key={item.id} quest={item} />)}

                        {!isLoading && items.length === 0 && (
                            <Group p={24} align="center" position="center">
                                <Text size={"14px"} weight={400} color="gray.6" lh={1.5}>
                                    Quest list is empty
                                </Text>
                            </Group>
                        )}
                    </Stack>
                </Card.Section>
            </Card>

            <Modal triggers={[ModalTrigger.ADD_GAME]}>
                <CreateForm />
            </Modal>
        </Stack>
    );
}
