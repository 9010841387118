import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mantine/core";
import ContentLoader from "components/ContentLoader";
import PageToolbar from "components/PageToolbar";
import useMutationData from "hooks/useMutationData";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { QuestForm } from "../../../features/Quests";
import { TQuest } from "../../../features/Quests/types";
import { useQuery } from "react-query";
import { api } from "utils/api";

export default function QuestDetailsPage() {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();

    const form = useForm<TQuest>({
        mode: 'onChange',
        resolver: yupResolver(yup.object({
            title: yup.string().required(),
            description: yup.string().required(),
            actionId: yup.string().required(),
            actionCount: yup.number().required(),
            startDate: yup.date().required(),
            endDate: yup.date().required(),
            questContents: yup.string().required(),
        })),
    });

    const { data: quest, isLoading } = useQuery<any>({
        queryFn: async () => id ? await api.get(`/admin/quests/${id}`) : await api.get(`/admin/quests/`),
        queryKey: ["QUEST", id],
    });

    const updateRequest = useMutationData({
        url: () => `/admin/quests/${id}`,
        method: 'patch',
        queryKeys: {
            invalidate: [
                { queryKey: ["QUEST", id] },
                { queryKey: ["QUEST"] },
                { queryKey: ["QUEST_LIST"] },
            ],
        }
    });

    const createRequest = useMutationData({
        url: () => `/admin/quests/`,
        method: 'post',
        queryKeys: {
            invalidate: [
                { queryKey: ["QUEST", id] },
                { queryKey: ["QUEST"] },
                { queryKey: ["QUEST_LIST"] }
            ],
        }
    });

    const onSubmit = form.handleSubmit(async (values) => {
        values.questContents = [values.questContents as any]
        if (id) {
            await updateRequest.mutateAsync(values);
            navigate(`/platform/quests/${id}`);
        } else {
            const data: any = await createRequest.mutateAsync(values);
            if (data) {
                const id = data.data?.data?.id;
                navigate(`/platform/quests/${id}`);
            }
        }
    });

    useEffect(() => {
        if (!quest || !id) return
         
        form.reset({
            title: quest.data.data.title,
            description: quest.data.data.description,
            actionId: String(quest.data.data.actionId),
            actionCount: quest.data.data.actionCount,
            questContents: String(quest.data.data.questContents[0].id),
            startDate: String(quest.data.data.startDate),
            endDate: String(quest.data.data.endDate),
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [quest, id]);

    if (isLoading) return <ContentLoader />

    return (
        <FormProvider {...form}>
            <Stack p={24} spacing={24}>
                <PageToolbar label={"Go Back"} backLink={"/platform/quests"} />
                <form onSubmit={onSubmit}>
                    <QuestForm />
                </form>
            </Stack>
        </FormProvider>
    )
}
