import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useParams } from "react-router-dom";
import { Controller, useFormContext } from "react-hook-form";
import { Dropzone, MIME_TYPES } from "@mantine/dropzone";
import {
  Center,
  Input,
  Group,
  Image,
  Paper,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";

export default function CouponEdit() {
  const { control } = useFormContext();
  const { id } = useParams();

  return (
    <Root radius={"md"} withBorder p={"lg"}>
      <Group grow spacing={"lg"} align={"flex-start"}>
        <Stack spacing={"lg"}>
          <Group grow>
            <Controller
              name="title"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  label="Name"
                  placeholder="Enter coupon name"
                  variant="filled"
                  error={fieldState.error?.message}
                  required
                  classNames={{ input: "input-field" }}
                  {...field}
                />
              )}
            />
          </Group>
          <Group grow>
            <Controller
              name="description"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  label="Description"
                  placeholder="Enter coupon description"
                  variant="filled"
                  autosize
                  required
                  classNames={{ input: "input-field" }}
                  error={fieldState.error?.message}
                  {...field}
                />
              )}
            />
          </Group>
          {/* Preview */}
          <Group grow>
            {!!id && (
              <Controller
                name="imagURL"
                control={control}
                render={({ field }) => (
                  <Input.Wrapper label={"Preview"} style={{ flex: 1 }}>
                    <Image
                      styles={{ image: { objectFit: "contain" } }}
                      fit={"contain"}
                      height={300}
                      src={field.value}
                      alt="preview picture"
                      withPlaceholder
                    />
                  </Input.Wrapper>
                )}
              />
            )}
            {!id && <div />}
          </Group>
          <Stack spacing={"lg"}>
            <Group grow>
              <Controller
                name="image"
                control={control}
                render={({ field, fieldState, formState }) => (
                  <Input.Wrapper
                    label="Upload image"
                    style={{ flex: 1 }}
                    error={fieldState.error?.message}
                  >
                    <Dropzone
                      loading={formState.isSubmitting}
                      maxSize={3 * 1024 ** 2}
                      accept={[MIME_TYPES.png, MIME_TYPES.jpeg]}
                      multiple={false}
                      onDrop={(files) => field.onChange(files[0])}
                      p={0}
                    >
                      <Center
                        style={{
                          pointerEvents: "none",
                          padding: Boolean(field.value) ? 0 : 16,
                        }}
                      >
                        {Boolean(field.value) && (
                          <Image
                            fit={"contain"}
                            styles={{ image: { objectFit: "contain" } }}
                            radius={"sm"}
                            src={URL.createObjectURL(field.value)}
                            alt="preview picture"
                          />
                        )}

                        {!Boolean(field.value) && (
                          <Stack spacing={0} align={"center"}>
                            <Text size="lg" inline>
                              Drag image here or click to select file
                            </Text>
                            <Text size="sm" color="dimmed" inline mt={8}>
                              Attach a file, supported formats: jpeg, png.
                              Each file should not exceed 10 MB
                            </Text>
                          </Stack>
                        )}
                      </Center>
                    </Dropzone>
                  </Input.Wrapper>
                )}
              />
            </Group>
          </Stack>
        </Stack>
      </Group>
    </Root>
  );
}

// CSS Helper
const InputCSS = css`
  display: flex;
  font-size: 14px;
  font-weight: 400;
  background: #fcfdfe;
  color: #9fa2b4;
  line-height: 1.5;
  min-height: 42px;
  outline: none;
  padding: 10px 16px;
  width: 100%;
  transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out,
    color 0.2s ease-in-out;

  &:hover,
  &:focus {
    color: #252733;
    border-color: #0095e5;
  }

  &::placeholder {
    color: rgb(75, 80, 109, 0.5);
  }
`;

const FieldCSS = css`
  flex: 1;

  & .input-field {
    ${InputCSS};
  }
`;
// Styling
const Root = styled(Paper) <any>`
  margin-top: 20px;
  overflow: hidden;
`;

const TextField = styled<any>(TextInput)`
  ${FieldCSS};
  position: relative;
  .mantine-TextInput-error {
    position: absolute;
    left: 0;
    bottom: -24px;
  }
  & .input-field {
    border: 1px solid ${({ error }) => (error ? "#f03e3e" : "#f0f1f7")};
  }
`;