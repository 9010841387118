import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from 'yup';
import { Button, Group, Stack, Text, TextInput } from "@mantine/core";
import useMutationData from "hooks/useMutationData";
import { useModalStore } from "features/Modal/store/modal.store";


type FormState = {
    name: string;
};

const schema = yup.object({
    name: yup.string().required(),
});


// Component
export function CreateForm() {
    const close = useModalStore(state => state.setClose)
    const createRequest = useMutationData({
        url: () => '/admin/games',
        method: 'post',
        queryKeys: {
            invalidate: [{ queryKey: ['GAME_LIST'] }],
        },
    })

    // Form Implementation
    const form = useForm<FormState>({
        mode: 'onChange',
        resolver: yupResolver(schema),
        defaultValues: {
            name: ''
        }
    });

    const onSubmit = form.handleSubmit(async (values) => {
        await createRequest.mutateAsync(values);
        close()
    });

    return (
        <form onSubmit={onSubmit}>
            <Stack pos={"relative"} spacing={0}>
                <Stack px={0} spacing={0}>
                    <Stack spacing={8} px={24}>
                        <Text size={'sm'} fw={'bold'}>Name:</Text>

                        <TextInput
                            {...form.register('name')}
                            placeholder={'Enter game name'}
                            variant={'filled'}
                            styles={{
                                root: { border: '2px solid #228be6', borderRadius: '6px' },
                                input: { border: '0px' },
                            }}
                        />

                    </Stack>
                    <Group grow align={'center'} px={24} my={24}>
                        <Button type='submit' loading={form.formState.isSubmitting}>
                            Create
                        </Button>
                    </Group>
                </Stack>
            </Stack>
        </form>
    );
};
