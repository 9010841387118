import { useCallback, useEffect } from "react";
import styled from "@emotion/styled";
import { Icon } from "@iconify/react";
import { Link, useParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { Button, Group, Stack, Text } from "@mantine/core";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ContentLoader from "components/ContentLoader";
import CouponEdit from "features/CouponsEdit";
import { useAuth } from "features/Auth/hooks/useAuth";
import { useAdmins } from "features/Admins/useAdmins";
import { useCoupon } from "features/CouponsEdit/hooks/useCoupon";
import { useCouponCreate } from "features/CouponsEdit/hooks/useCouponCreate";
import { useCouponUpdate } from "features/CouponsEdit/hooks/useCouponUpdate";
import { useCouponDelete } from "features/CouponsEdit/hooks/useCouponDelete";
import { api } from "index";
import { useMerchantNavigation } from "../../../hooks/useMerchantNavigation";
import { ICoupon } from "api/interfaces/coupon";

// Validation Schema
const schema = yup
  .object({
    title: yup
      .string()
      .max(32, `Name is too long. Maximum length is 32 chars.`)
      .lowercase()
      .trim()
      .required("Required"),
    description: yup.string().required(),
    imagURL: yup.string(),
  }).required();

// Form Values Types
interface FormState {
  type: "update" | "create";
  title: string;
  description: string;
  imagURL: string;
  image?: File;
};

// Page
export default function CouponsUpdate() {
  const { user } = useAuth();
  const { id } = useParams();
  const { isLoading } = useAdmins();
  const { coupon } = useCoupon(id!);
  const { handleCreate } = useCouponCreate();
  const { handleUpdate } = useCouponUpdate(Number(id));
  const { handleDelete } = useCouponDelete(Number(id));
  const { route } = useMerchantNavigation();

  const form = useForm<FormState>({
    resolver: yupResolver(schema),
    defaultValues: {
      type: id ? "update" : "create",
      title: "",
      imagURL: "",
      description: "",
      image: undefined,
    },
  });

  const { handleSubmit } = form;

  const onSubmit = useCallback(
    async (input: any) => {
      const data = {} as ICoupon;

      try {
        if (input.image) {
          const response = await api.files.uploadImage(input.image);
          Object.assign(data, {
            title: input.title,
            description: input.description,
            imagURL: response.url,
          });
        } else {
          Object.assign(data, {
            title: input.title,
            description: input.description,
            imagURL: input.imagURL,
          });
        }

        if (!coupon) {
          await handleCreate(data);
        }

        if (!!coupon) {
          await handleUpdate({ ...data, id: coupon.id });
        }
      } catch (e: any) {
        console.log("error", e);
      }
      finally {
        form.setValue('image', undefined)
      }
    },
    [form, handleCreate, handleUpdate, coupon]
  );

  // Update Form Data
  useEffect(() => {
    if (!coupon) return;

    form.reset({
      type: "update",
      title: coupon.title,
      imagURL: coupon.imagURL,
      description: coupon.description,
    });
  }, [coupon, form, id, user]);

  if ((id && !coupon) || isLoading) return <ContentLoader />;

  return (
    <FormProvider {...form}>
      <Root onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={0}>
          <Group position="apart">
            <Group position="left">
              <BackLink to={`${route}/coupons`}>
                <span className={"icon"}>
                  <Icon icon={"fluent:arrow-left-12-filled"} />
                </span>
                <Text size="xl" weight={"bold"}>
                  Back to Coupons
                </Text>
              </BackLink>
            </Group>
            <Group position="right">
              {!!coupon && (
                <Button
                  leftIcon={
                    <Icon icon={"fluent:delete-24-filled"} height={18} />
                  }
                  onClick={handleDelete}
                  radius={"sm"}
                  color={"red"}
                  variant={"filled"}
                >
                  Delete
                </Button>
              )}
              {!coupon && (
                <Button
                  leftIcon={
                    <Icon icon={"fluent:delete-24-filled"} height={18} />
                  }
                  component={Link}
                  to={`${route}/coupons`}
                  radius={"sm"}
                  color={"red"}
                  variant={"filled"}
                >
                  Cancel
                </Button>
              )}

              <Button
                type="submit"
                leftIcon={<Icon icon={"fluent:save-24-filled"} height={18} />}
                radius={"sm"}
                color={"teal"}
                variant={"filled"}
                loading={form.formState.isSubmitting}
              >
                {coupon ? "Update" : "Create"}
              </Button>
            </Group>
          </Group>

          <CouponEdit />
        </Stack>
      </Root>
    </FormProvider>
  );
}

// Styling
const Root = styled.form`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const BackLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;

  color: #2c3e50;

  & {
    transition-property: color;
    transition-duration: 250ms;
    transition-timing-function: ease-in-out;
  }

  &:hover {
    color: #0083ce;
  }

  & .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    margin-right: 8px;
  }

  & .title {
    font-size: 20px;
    font-weight: bold;
  }
`;
